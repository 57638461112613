<template>
  <b-card-actions
    ref="discountsList"
    title="Cupons"
    class="top-level"
    action-refresh
    @refresh="updateRows({})"
  >
    <b-row align-v="start">

      <b-col md="3">
        <b-form-group>
          <label>Pesquisar</label>
          <b-form-input
            v-model="$store.state.discounts.filters.search"
            :disabled="state === 'loading'"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
            autocomplete="off"
            @change="(value)=>pesquisa()"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row class="mb-1 mb-md-0">
      <b-col
        cols="12"
        md="6"
      >
        <b>{{ $store.state.discounts.discountsList.length }}</b> de <b>{{ $store.state.discounts.totalCount }}</b> Descontos
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Selecionar tudo"
          label-cols="9"
        >
          <b-form-checkbox
            v-model="allSelected"
            class="mr-0 mt-50"
            name="all-selected"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          class="float-right"
          variant="success"
          :to="{ name : 'discount-create'}"
          :disabled="state === 'loading'"
        >
          Novo desconto
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-card
        v-for="discount in rows"
        :key="discount.id"
        :class="`mb-1 list-row ${selected.includes(discount.id) ? 'row-selected' : ''}`"
        @click="selectCard(discount.id)"
      >
        <b-row align-v="center">
          <b-col
            lg="4"
            cols="12"
          >
            {{ discount.code }}
          </b-col>
          <b-col
            lg="2"
            cols="6"
            align="center"
          >
            <span style="font-size: 10px;">Disponível</span><br>
            <feather-icon
              v-if="discount.available"
              icon="CheckIcon"
              class="text-success"
              size="18"
            />
            <feather-icon
              v-else
              icon="XIcon"
              class="text-danger"
              size="18"
            />
          </b-col>
          <b-col
            lg="2"
            cols="6"
            align="center"
          >
            <span style="font-size: 10px;">Frete Grátis</span><br>
            <feather-icon
              v-if="discount.free_delivery"
              icon="CheckIcon"
              class="text-success"
              size="18"
            />
            <feather-icon
              v-else
              icon="XIcon"
              class="text-danger"
              size="18"
            />
          </b-col>
          <b-col
            lg="2"
            cols="6"
            align="center"
          >
            <span style="font-size: 10px;">Valor</span><br>
            {{ `${discount.value[0].discount} ${discount.value[0].unit}` }}
          </b-col>
          <b-col
            lg="2"
            cols="6"
            align="center"
          >
            <span style="font-size: 10px;">Usado</span><br>
            {{ discount.orders_count }}
          </b-col>

        </b-row>
        <b-row
          v-if="selected.includes(discount.id) && selected.length === 1"
          align-v="center"
          class="pb-1"
        >
          <b-col
            lg="3"
            cols="12"
          >
            <b-button
              :to="{ name: 'discount-edit', params: { id: discount.id } }"
              variant="success"
              :disabled="state === 'loading'"
            ><feather-icon icon="EditIcon" /> Editar</b-button>
          </b-col>
          <b-col
            lg="3"
            cols="12"
          >
            <b-button
              variant="danger"
              @click="deleteDiscount(discount.id)"
            ><feather-icon icon="EditIcon" /> Deletar</b-button>
          </b-col>
        </b-row>
        <b-row
          v-for="order in discount.orders"
          v-if="selected.includes(discount.id) && selected.length === 1"
          :key="order.id"
          align-v="center"
          class="pb-1"
        >
          <b-col
            cols="12"
          >
            <span
              class="text-nowrap flex-parent"
            >
              {{ order.identification }}.&nbsp;
              <!-- <b-avatar
                    :src="order.gravatar_url"
                    class="mx-1"
                  /> -->
              <span class="flex-child long-and-truncated">{{ order.client_full_name }}</span>
            </span>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="d-flex justify-content-between flex-wrap mb-1">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Tamanho da página
        </span>
        <b-form-select
          v-model="$store.state.orders.filters.pageSize"
          :disabled="state === 'loading'"
          :options="['1','5','10', '50', '100', '250']"
          class="mx-1"
          @input="(value)=>updateRows({ pageSize: value, page: 1 })"
        />
      </div>
      <div>
        <b-pagination
          v-model="$store.state.orders.filters.page"
          :disabled="state === 'loading'"
          :total-rows="$store.state.orders.totalCount"
          :per-page="$store.state.orders.filters.pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value)=>updateRows({ page: value })"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      allSelectState: false,
    }
  },
  computed: {
    rows() {
      return this.$store.state.discounts.discountsList
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.discounts.discountsList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.discounts.discountsList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    })
    this.pesquisa()
    this.searchFilter = this.$store.state.discounts.filters.search ? this.$store.state.discounts.filters.search : ''
  },
  methods: {
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)
      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    pesquisa() {
      this.state = 'loading'
      try {
        this.$refs.discountsList.showLoading = true
      } catch (error) {

      }
      this.$store.commit('discounts/firstPage')
      this.$store.dispatch('discounts/getDiscounts', {}).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.discounts.discountsList.find(discount => discount.id === item))
        this.state = 'ready'
        try {
          this.$refs.discountsList.showLoading = false
        } catch (error) {

        }
      })
    },
    updateRows(filters) {
      this.state = 'loading'
      try {
        this.$refs.discountsList.showLoading = true
      } catch (error) {

      }
      this.$store.dispatch('discounts/getDiscounts', filters).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.discounts.discountsList.find(discount => discount.id === item))
        this.state = 'ready'
        try {
          this.$refs.discountsList.showLoading = false
        } catch (error) {

        }
      })
    },
    deleteDiscount(discountId) {
      this.$store.dispatch('discounts/deleteDiscount', discountId).then(() => {
        this.pesquisa()
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
  color: inherit;
}

.row-selected {
  background: #004259;
  color: white !important;
}

.row-selected:hover {
  background: #004159cc;
  color: white;
}

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
  // margin: 1em 0;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#filter_status{
  border-radius: 6px;
}
</style>
